<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5" style="min-height: 90vh">
      <bullet :title="'Psychogram Template'" />
      <div></div>
    </div>
  </div>
</template>

<script>
import Bullet from "../../components/Etc/bullet.vue";
import navbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  components: { navbarProfile, Bullet },
  name: "psychogramTemplate",
  data() {
    return {
      loading: false,
      dataSet: null,
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Psychogram Template",
          disabled: true,
          href: "#",
        },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {},
  },
};
</script>

<style scoped></style>
